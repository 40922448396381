@import "../../../assets/styles/global.scss";

.container {
  padding-top: 140px;

  .wrapper {
    @include subtitle5;

    color: #e0e0e0;
    position: relative;
    width: 100%;
    z-index: 2;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .back {
      @include subtitle6m;

      align-items: center;
      color: #ead40c;
      cursor: pointer;
      display: flex;
      gap: 16px;
      margin-bottom: 78px;
    }

    .title {
      font-size: 48px;
      font-weight: 900;
      text-transform: uppercase;
      font-family: "HelveticaNeue";

      .yellow {
        color: #ead40c;
      }
    }

    .info {
      line-height: 115%;
      margin-top: 24px;
      width: 85%;

      .txt {
        margin-top: 8px;
      }
    }

    .btn {
      display: block;
      margin-top: 24px;

      button {
        @include subtitle6m;
        align-items: center;
        background-color: #ead40c;
        border-radius: 8px;
        cursor: pointer;
        display: flex;
        gap: 8px;
        height: 46px;
        padding-left: 22px;
        padding-right: 22px;
        transition: background-color 0.3s;

        &:hover {
          background-color: darken(#ead40c, 10%);
        }
      }
    }

    .pic {
      width: auto;
      height: 100%;
      max-width: 50%; 
      object-fit: cover;
    }
  }
}

@media (max-width: 850px) {
  .container {
    padding-top: 104px;

    .wrapper {
      width: 100%;
      flex-direction: column; 
      align-items: flex-start;

      .back {
        margin-bottom: 24px;
      }

      .title {
        font-size: 24px;
      }

      .info {
        font-size: 16px;
        width: 100%;
      }

      .pic {
        width: auto;
        height: 100%;
        max-width: 100%;
        object-fit: cover;
        margin-top: 40px;
      }

      .btn {
        width: 100%; 
        button {
          width: 100%;
          justify-content: center; 
        }
      }
    }
  }
}
