@import '../../assets/styles/global.scss';

.steam {
  background-color: #0000004D;
  border-radius: 8px;
  padding: 21px 18px;
  width: 50%;

  .title {
    @include subtitle1b;

    align-items: center;
    border-bottom: 1px solid #DCDCDC26;
    color: #fff;
    display: flex;
    gap: 8px;
    padding-bottom: 21px;
  }

  .txt {
    @include subtitle5;

    align-items: center;
    color: #EAD40C;
    display: flex;
    gap: 10px;
    margin-top: 26px;
  }

  .info {
    @include body1;

    color: #E0E0E0;
    margin-top: 20px;
  }

  .btn {
    margin-top: 20px;

    button {
      @include subtitle6m;

      align-items: center;
      background-color: #EAD40C;
      border-radius: 8px;
      display: flex;
      gap: 8px;
      height: 46px;
      padding-left: 22px;
      padding-right: 22px;
    }
  }
}

@media (max-width: 850px) {
  .steam {
    margin-bottom: 24px;
    width: 100%;

    .title {
      font-size: 20px;
    }

    .txt {
      font-size: 20px;
    }

    .btn {

      button {
        font-size: 14px;
        height: 36px;
      }
    }
  }
}