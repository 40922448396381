@import "../../assets/styles/global.scss";

.wrapper {
  position: relative;
  background-image: url("../../assets/images/bg_desktop.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 50px;
  
  .info {
    

    .section {
      padding: 0 20px;
      margin-top: 60px;
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      .banner_pic {
        display: block;
        max-width: 100%; 
        height: auto; 
        object-fit: cover; 
        margin: 0 auto; 
      }
      .banner_pic_mob {
        display: none;
      }
      .group {
        // max-width: 820px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: baseline;
        gap: 10px;
        margin-left: 100px;

        .title {
          @include h2;

          letter-spacing: 0.01em;
          text-transform: uppercase;
          color: #ffffff;
          line-height: 1.1em;
          white-space: nowrap;
        }

        .subtitle {
          @include subtitle2m;

          color: #ffffff;
        }

        .btn {
          @include subtitle4m;

          border-radius: 8px;
          width: 260px;
          height: 50px;
          background: #EAD40C;
          line-height: 130%;
          text-transform: capitalize;
          color: #1b1e23;
          cursor: pointer;
          margin-top: 30px;
        }
      }
    }

    .container {
      color: #ffffff;
      z-index: 2;

      .title {
        @include h1;
        margin-bottom: 20px;
      }

      .subtitle {
        @include subtitle5;
        line-height: 120%;
        margin-bottom: 24px;
        width: 45%;
      }

      .btn {
        @include subtitle6m;
        border-radius: 8px;
        cursor: pointer;
        padding: 10px 22px;
      }

      .reg {
        background-color: #ead40c;
        color: #1b1e23;
      }

      .platforms {
        @include df-aic;
        gap: 32px;
        margin-top: 40px;
      }
    }
  }

  .bannerDesk {
    background-image: url("../../assets/images/banner.png");
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: right;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  .banner {
    display: none;
  }
}

@media (max-width: 1442px) {
  .wrapper {
    .info {
      .section {
        .group {
          margin-left: 100px;
          margin-top: 40px;
          .title {
            font-size: 34px;
          }
        }
        

      }


    }
  }
}

@media (max-width: 1000px) {
  .wrapper {
    .info {
      .section {
        .group {
          
        }
        margin-bottom: 20px;
        .banner_pic {
          width: auto;
        }
      }

      .container {
        .title {
          width: 50%;
        }
      }
    }
  }
}

@media (max-width: 850px) {
  .wrapper {
    display: block;
    padding-top: 100px;

    .info {
      height: unset;
      .section {
        .group {
          margin-left: 50px;
        }
      }

      .container {
        .title {
          @include h2;
          width: 100%;
        }

        .subtitle {
          width: 90%;
        }

        .platforms {
          img {
            width: 180px;
          }
        }
      }
    }

    .bannerDesk {
      display: none;
    }

    .banner {
      display: block;

      img {
        width: 100%;
      }
    }
  }
}

@media (max-width: 679px) {
  .wrapper {
    .info {
      .section {
        margin-left: 0px;
        margin-top: 5px;
        padding: 0px 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 0px;
        width: 100%;
        .banner_pic {
          display: none;
        }
        .banner_pic_mob {
          display: block;
          margin-top: 30px;
          width: 107%; 
          height: auto; 
        }

        .group {
          width: 100%;
          align-items: center;
          gap: 7px;
          margin-left: 0px;
          .btn {
            place-self: center;
          }
          .subtitle {
            @include subtitle5;
          }

          .title {
            font-size: 28px;
            text-align: center;
            white-space: normal;
          }

          .btn {
            font-size: 18px;
            margin-top: 30px;
          }
        }
      }

      .container {
        z-index: 0;

        .platforms {
          img {
            width: 150px;
          }
        }
      }
    }
  }
}
