@import "../../assets/styles/global.scss";

.wrapper {
  position: relative;
  margin-top: 60px;

  .container {
    width: 100%;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .section {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 90%;
      .title {
        font-weight: 900;
        font-size: 40px;
        letter-spacing: 0.01em;
        text-transform: uppercase;
        color: #ffffff;
        font-family: "Parimatch-Regular";
        font-weight: 800;
        font-size: 64px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        
      }
      .group {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 30px;
        margin-top: 20px;
        .group_1 {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;
          gap: 20px;
          
          .pic1 {
            box-shadow: 0px 4px 6.8px 0px rgba(19,22,28,1);
            background: url("../../assets/images/home_product_1.png");
            background-repeat: no-repeat;
            background-size: 100%;
            width: 270px;
            height: 325px;
            border-radius: 8px;
            .text {
              margin-top: 250px;
              display: flex;
              flex-direction: column;
              gap: 12px;
              padding: 0 16px;
              .name {
                @include subtitle6m;

                color: #fff;
              }
              .desc {
                @include subtitle3b;
                
                color: #f8ff13;
              }
            }
          }
          .pic2 {
            box-shadow: 0px 4px 6.8px 0px rgba(19,22,28,1);
            background: url("../../assets/images/home_product_2.png");
            background-repeat: no-repeat;
            background-size: 100%;
            width: 270px;
            height: 325px;
            border-radius: 8px;
            .text {
              margin-top: 250px;
              display: flex;
              flex-direction: column;
              gap: 12px;
              padding: 0 16px;
              .name {
                @include subtitle6m;

                color: #fff;
              }
              .desc {
                @include subtitle3b;

                color: #f8ff13;
              }
            }
          }
          .pic3 {
            box-shadow: 0px 4px 6.8px 0px rgba(19,22,28,1);
            background: url("../../assets/images/home_product_3.png");
            background-repeat: no-repeat;
            background-size: 100%;
            width: 270px;
            height: 325px;
            border-radius: 8px;
            .text {
              margin-top: 250px;
              display: flex;
              flex-direction: column;
              gap: 12px;
              padding: 0 16px;
              .name {
                @include buttonlarge;

                color: #fff;
              }
              .desc {
                @include subtitle3b;

                color: #f8ff13;
              }
            }
          }
          .pic4 {
            box-shadow: 0px 4px 6.8px 0px rgba(19,22,28,1);
            background: url("../../assets/images/home_product_4.png");
            background-repeat: no-repeat;
            background-size: 100%;
            width: 270px;
            height: 325px;
            border-radius: 8px;
            .text {
              margin-top: 250px;
              display: flex;
              flex-direction: column;
              gap: 12px;
              padding: 0 16px;
              .name {
                @include subtitle6m;

                color: #fff;
              }
              .desc {
                @include subtitle3b;

                color: #f8ff13;
              }
            }
          }
        }
        .group_2 {
          background-image: url("../../assets/images/bg_section_2_desktop.png");
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          border-radius: 8px;
          width: 100%;
          height: auto;
          display: flex;
          flex-direction: row;
          gap: 50px;
          align-items: center;
          .group_2_img {
            width: auto;
            height: auto;
          }
          .group_2_img_mob {
            display: none;
          }
          .desc_group {
            display: flex;
            flex-direction: column;
            gap: 10px;
            align-items: baseline;
            .btn {
              @include subtitle4m;

              border-radius: 8px;
              width: 260px;
              height: 50px;
              background: #EAD40C;
              margin-top: 30px;
              line-height: 130%;
              text-transform: capitalize;
              color: #1b1e23;
              cursor: pointer;
            }
            .desc_title {
              @include h3;
              
              text-transform: uppercase;
              color: #fff;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1153px) {
  .wrapper {
    .container {
      .section {

        .group {

          .group_2 {
            background: linear-gradient(180deg, #2f364a 0%, #1b1e23 100%);
            display: flex;
            flex-direction: column;
            align-items: center;
            .group_2_img {
              display: none;
            }
            .group_2_img_mob {
              display: block;
            }
            .desc_group {
              align-items: center;
              gap: 0px;
              .btn {
                border-radius: 8px;
                width: 129px;
                height: 50px;
              }
              .desc_title {
                font-weight: 700;
                font-size: 32px;
                text-transform: uppercase;
                text-align: center;
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .wrapper {
    .container {
      .section {
        .title {
          margin-left: 0px;
        }
        .group {
          align-items: center;
          .group_1 {
            margin-left: 0px;
          }
          .group_2 {
            margin-left: 0px;
          }
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .wrapper {
    .container {
      .section {
        .title {
          font-weight: 400;
          font-size: 40px;
          line-height: 100%;
          letter-spacing: 0.03em;
          text-transform: uppercase;
          color: #fff;
        }
        .group {
          .group_1 {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 1fr);
            .pic1,
            .pic2,
            .pic3,
            .pic4 {
              border-radius: 8px;
              width: 156px;
              height: 202px;
              .text {
                margin-top: 130px;
                .name {
                  font-weight: 500;
                  font-size: 12px;
                  color: #fff;
                }
                .desc {
                  font-weight: 700;
                  font-size: 14px;
                  color: #f8ff13;
                }
              }
            }
          }
          .group_2 {
            background: linear-gradient(180deg, #2f364a 0%, #1b1e23 100%);
            display: flex;
            flex-direction: column;
            align-items: center;
            .group_2_img {
              display: none;
            }
            .group_2_img_mob {
              display: block;
            }
            .desc_group {
              align-items: center;
              gap: 0px;
              .btn {
                border-radius: 8px;
                width: 129px;
                height: 50px;
              }
              .desc_title {
                font-weight: 700;
                font-size: 32px;
                text-transform: uppercase;
                text-align: center;
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
}
