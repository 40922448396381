@import "../../assets/styles/global.scss";

.container {
  padding-top: 124px;
  width: 50%;

  .wrapper {
    align-items: center;
    background-color: #0000004d;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    padding-top: 125px;
    padding-bottom: 125px;
    margin-top: 35px;

    .content {
      text-align: center;
      width: 55%;

      .title {
        @include h2;

        color: $c-white;
        font-size: 40px;
        margin-bottom: 24px;
      }

      .info {
        @include subtitle5;

        color: $c-light-grey;
      }

      .help {
        display: flex;
        gap: 20px;
        justify-content: center;
        margin-top: 24px;

        .tg {
          align-items: center;
          background-color: #19334f;
          border-radius: 8px;
          display: flex;
          justify-content: center;
          height: 76px;
          transition: all 0.2s linear;
          width: 100px;

          &:hover {
            background-color: #244971;
          }
        }

        .phone {
          align-items: center;
          background-color: #3a2159;
          border-radius: 8px;
          color: #ae6fff;
          display: flex;
          gap: 8px;
          justify-content: center;
          height: 76px;
          transition: all 0.2s linear;
          width: 100px;

          &:hover {
            background-color: #4a2c6f;
          }
        }
      }
    }
  }
}

@media (max-width: 1100px) {
  .container {
    padding-top: 104px;
    width: 90%;

    .wrapper {
      .content {
      }
    }
  }
}

@media (max-width: 850px) {
  .container {
    .wrapper {
      padding-bottom: 20px;
      padding-top: 20px;

      .content {
      }
    }
  }
}

@media (max-width: 600px) {
  .container {
    .wrapper {
      .content {
        padding-left: 34px;
        padding-right: 34px;
        width: 100%;

        .title {
          @include h3;
        }

        .info {
          @include body1;
        }
      }
    }
  }
}
