@import "../../assets/styles/global.scss";

.wrapper {
  position: relative;
  background-image: url("../../assets/images/prefooter_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  .section {
    padding: 0 20px;
    margin-top: 60px;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    .group_img {
      
    }
    .group {
      max-width: 550px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: baseline;
      gap: 25px;
      margin-left: 100px;
      .title {
        font-family: "Parimatch-regular";
        font-weight: 800;
        font-size: 70px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        text-align: center;
        color: #fff;
        white-space: nowrap;
      }
      .desc_group {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .desc {
          font-weight: 700;
          font-size: 24px;
          color: #fff;
        }
      }

      .btn {
        border-radius: 8px;
        width: 260px;
        height: 50px;
        background:#EAD40C;
        font-weight: 500;
        font-size: 20px;
        line-height: 130%;
        text-transform: capitalize;
        color: #1b1e23;
        cursor: pointer;
      }
    }
  }
}

@media (max-width: 1000px) {
  .wrapper {
    .section {
      .group_img {
        width: 60%;
      }
      .group {
        margin-left: 0px;
      }
    }
  }
}
@media (max-width: 679px) {
  .wrapper {
    background: linear-gradient(
      180deg,
      rgba(97, 0, 255, 0) 0%,
      rgba(97, 0, 255, 0.3) 100%
    );
    .section {
      display: flex;
      flex-direction: column;
      .group_img {
        width: 100%;
        margin-top: 25px;
      }
      .group {
        align-items: center;
        .title {
          font-size: 40px;
        }
        .desc_group {
          text-align: center;
          .desc {
            font-size: 20px;
          }
        }
        .btn {
          width: 150px;
          height: 50px;
        }
      }
    }
  }
}
