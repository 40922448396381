@import '../../assets/styles/global.scss';

.wrapper {
  position: relative;

  .container {
    padding-top: 160px;

    .title {
      @include h3;

      color: #ffffff;
    }

    .main {
      display: flex;
      gap: 24px;
      margin-top: 40px;

      .left {
        background-color: #0000004D;
        border-radius: 4px;
        height: 100%;
        padding: 24px;
        width: 30%;

        .edit {
          @include subtitle4m;

          align-items: center;
          color: #EAD40C;
          display: flex;
          gap: 8px;
        }

        .exit {
          color: #E0E0E0;
          cursor: pointer;
          margin-top: 20px;
          transition: all .2s linear;

          path {
            transition: all .2s linear;
          }

          &:hover {
            color: #FFEB31;
          }

          &:hover path {
            fill: #FFEB31;
          }
        }
      }

      .right {
        background-color: #0000004D;
        border-radius: 4px;
        padding: 16px;
        padding-bottom: 32px;
        padding-top: 32px;
        width: 70%;

        .rightTitle {
          @include subtitle3b;

          color: #FFFFFF;
        }

        .profileImage {
          display: inline-flex;
          margin-top: 32px;
          position: relative;

          img {
            width: 92px;
          }

          .picture {
            bottom: 0;
            position: absolute;
            right: 0;
            width: 32px;
          }
        }

        .rule {
          @include body2;

          color: #E0E0E0;
          margin-top: 12px;

          span {
            color: #F44336;
          }
        }

        .inputs {
          @include body1;

          color: #FFFFFF;
          display: flex;
          justify-content: space-between;
          margin-top: 32px;

          .inputsItem {
            width: 48%;

            .inputsTitle {
              margin-bottom: 12px;

              span {
                color: #F44336;
              }
            }

            .input {
              align-items: center;
              background-color: #DCDCDC0D;
              border-radius: 8px;
              display: flex;
              height: 56px;

              input {
                @include body1;

                background-color: unset;
                border: none;
                color: #E0E0E0;
                outline: none;
                padding: 12px;

                &::placeholder {
                  color: #E0E0E0;
                }
              }
            }
          }
        }
      }
    }

    .btns {
      display: flex;
      gap: 24px;
      justify-content: end;
      margin-top: 24px;
    }
  }

  .closeWrapper {
    align-items: center;
    background-color: rgba(0, 0, 0, .5);
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;

    .closeModal {
      @include h3;

      background-color: $c-black;
      border-radius: 8px;
      color: $c-white;
      padding: 20px;

      .btns {
        align-items: center;
        display: flex;
        gap: 16px;
        justify-content: end;
        margin-top: 36px;
      }
    }
  }
}

@media (max-width: 850px) {
  .wrapper {
    .container {
      padding-top: 160px;

      .main {
        display: block;

        .left {
          margin-bottom: 24px;
          width: 100%;
        }

        .right {
          width: 100%;

          .inputs {
            display: block;

            .inputsItem {
              margin-bottom: 24px;
              width: 100%;
            }
          }
        }
      }
    }

    .closeWrapper {

      .closeModal {
        @include h3;

        width: 90%;

        .btns {}
      }
    }
  }
}