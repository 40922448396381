@import "../../../assets/styles/global.scss";

.quest_container {
  width: 80%;
  margin: 0 auto;
  margin-top: 50px;
  position: relative;
  .quest_wrapper {
    background-color: rgba(0, 0, 0, 0.3);
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: 8px;
    padding: 16px;
    transition: all 0.2s linear;
    height: auto;
    max-width: 368px;
    width: 100%;
    .quest_inner {
      display: flex;
      flex-direction: column;
      gap: 15px;
      .quest_inner_img {
        object-fit: cover;
        width: 100%;
      }
      .quest_inner_name {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .quest_inner_name_up {
          color: #ae6fff;
          @include subtitle3b;
        }
        .quest_inner_name_status {
          border-radius: 8px;
          font-family: "Roboto";
          font-size: 16px;
          font-weight: 500;
          padding: 12px;
          background-color: rgba(241, 248, 19, 0.15);
          color: #f1f813;
          min-width: 82px;
          display: flex;
          justify-content: center;
        }
      }
      .quest_inner_desc_block {
        display: flex;
        flex-direction: column;
        gap: 12px;
        .quest_inner_desc_block_name_up {
          @include subtitle1b;
          color: #ffffff;
        }
        .quest_inner_desc_block_name_down {
          @include body1;
          color: #e0e0e0;
        }
        .quest_inner_desc_block_chips {
          display: flex;
          flex-direction: row;
          gap: 15px;
          .quest_inner_desc_block_chips_up {
            border-radius: 8px;
            font-family: "Roboto";
            font-size: 16px;
            font-weight: 500;
            padding: 12px;
            background-color: rgba(241, 248, 19, 0.15);
            color: #f1f813;
          }
          .quest_inner_desc_block_chips_down {
            border-radius: 8px;
            font-family: "Roboto";
            font-size: 16px;
            font-weight: 500;
            padding: 12px;
            background-color: rgba(191, 46, 26, 0.15);
            color: #bf2e1a;
          }
        }
      }
      .quest_inner_button_btn {
        cursor: pointer;
        @include subtitle6m;

        background-color: #ead40c;
        border-radius: 8px;
        cursor: pointer;
        margin-top: 16px;
        padding-top: 12px;
        padding-bottom: 12px;
        transition: all 0.2s linear;
        width: 100%;

        &:hover {
          background-color: #ffeb31;
        }
      }
    }
  }
}

.container {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-bottom: 44px;
  padding-top: 182px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(27, 30, 35, 0.8);
  }

  .wrapper {
    margin: 0 auto;
    width: 80%;

    .info {
      position: relative;
      // z-index: 1;

      .title {
        @include h2;

        color: #ffffff;
        margin-bottom: 16px;
        text-transform: uppercase;
      }

      .modalGame {
        display: flex;
        margin-bottom: 16px;

        .modalGameItem {
          align-items: center;
          background-color: #611d14;
          border-radius: 100px;
          color: #fff;
          display: flex;
          gap: 4px;
          font-size: 16px;
          font-weight: 400;
          padding: 13px 16px;
        }

        .modalGameItemCS {
          background-color: #614714;
        }
        .modalGameItemLoL {
          background-color: #614714;
        }
      }

      .dates {
        @include subtitle5;

        color: #e0e0e0;
        display: flex;
        gap: 25px;
        margin-bottom: 16px;

        .date {
          @include subtitle4m;

          color: #ffffff;
          margin-top: 7px;
        }
      }

      .description {
        @include subtitle5;

        color: #e0e0e0;
        line-height: 130%;
        margin-bottom: 16px;
      }
      .button_disabled {
        @include subtitle5;
        border-radius: 8px;
        width: 336px;
        height: 46px;
        background: grey;
        cursor: pointer;
        color: rgba(224, 224, 224, 0.7);
        
      }
    }
  }
}

.wrapperQuests {
  @include h3;

  color: #fff;
  margin: 50px auto;
  position: relative;
  width: 80%;
  z-index: 3;
}

@media (max-width: 850px) {
  .container {
    .wrapper {
      width: 90%;

      .info {
        .title {
          @include h3;

          line-height: 130%;
        }
      }
    }
  }
}
