@import '../../../assets/styles/global.scss';

.pagination {
  align-items: center;
  display: flex;
  gap: 8px;
  padding-bottom: 16px;
  padding-right: 24px;

  .btn {
    @include body2;

    color: #ffffff;
    cursor: pointer;
    height: 36px;
    padding-left: 14px;
    padding-right: 14px;
  }

  .active {
    background-color: $c-dark-yellow;
    border-radius: 8px;
    color: $c-yellow;
  }

  .next,
  .prev {
    align-items: center;
    cursor: pointer;
    justify-content: center;
    display: flex;
    height: 36px;

    path {
      fill: $c-white;
    }
  }

  .prev {
    transform: rotate(-180deg);
  }
}

@media (max-width: 850px) {
  .pagination {
    padding-bottom: 8px;
    padding-right: 8px;
  }
}