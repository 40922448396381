@import '../../assets/styles/global.scss';

.container {
  display: none;
  z-index: 1;
}

@media (max-width: 1100px) {
  .container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    left: 0;
    margin: 0 auto;
    padding-top: 22px;
    position: absolute;
    right: 0;
    top: 0;
    width: 90%;
    padding-top: 70px;
    .right {
      align-items: center;
      display: flex;
      gap: 24px;

      .points {
        @include subtitle4m;

        color: #EAD40C;
      }
    }
  }
}