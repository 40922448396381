@import '../../assets/styles/global.scss';

.container {

  .title {
    @include h2;

    color: #ffffff;
    text-align: center;
  }

  .sliderWrapper {
    // position: absolute;
  }

  .rules {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;
    margin-top: 60px;

    .nextArrow {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
    }

    .prevArrow {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
    }
  }
}

@media (max-width: 1200px) {
  .container {
    
    .rules {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media (max-width: 850px) {
  .container {
    margin-top: 100px;
    
    .rules {
      // grid-template-columns: repeat(1, 1fr);
      // margin: 0 auto;
      // width: 60%;
      display: block;
    }
  }
}

@media (max-width: 500px) {
  .container {
    
    .rules {
      width: unset;
    }
  }
}