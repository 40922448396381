$c-white: #ffffff;

$c-black: #1B1E23;
$c-light-black: #242424;

$c-dark-grey: rgba(36, 36, 36, .3);
$c-light-grey: #E0E0E0;
$c-light-grey-lines: rgba(220, 220, 220, .1);

$c-yellow: #EAD40C;
$c-light-yellow: #FFEB31;
$c-dark-yellow: #333217;
$c-dark-yellow-focus: #494711;

$c-blue-main: #0CDCEA;
$c-light-blue: #69F6FF;
$c-dark-blue-btns: #193B41;
$c-dark-blue-hover: #0A5360;

$c-red: #F44336;
$c-dark-red: #351C1D;

$c-green: #07EF9C;
$c-dark-green: #11362C;

$c-blue: #1D92FF;
$c-dark-blue: #19334F;

$c-parimatch: #F1F813;
$c-parimatch-light: rgb(241, 248, 19, .15);

$c-dota: #BF2E1A;
$c-dota-light: rgba(191, 46, 26, .15);



