@import "../../assets/styles/global.scss";

.wrapper {
  position: relative;
  margin-top: 80px;

  .container {
    width: 100%;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .section {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 90%;

      .title {
        font-weight: 900;
        font-size: 40px;
        letter-spacing: 0.01em;
        text-transform: uppercase;
        color: #ffffff;
        font-family: "Parimatch-Regular";
        font-weight: 800;
        font-size: 64px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #f8ff13;
      }

      .group {
        background-image: url("../../assets/images/bg_section_desktop.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        border-radius: 24px;
        width: 100%;
        height: 464px;
        display: flex;
        gap: 10px;
        justify-content: space-between;
        align-items: center;
        place-self: center;
        .img {
          width: auto;
        }
        .img_mob {
          display: none;
        }

        .desc {
          padding-left: 40px;
          display: flex;
          flex-direction: column;
          align-items: baseline;
          .button {
            @include subtitle4m;

            border-radius: 8px;
            width: 260px;
            height: 50px;
            background: #ead40c;
            margin-top: 30px;
            line-height: 130%;
            text-transform: capitalize;
            color: #1b1e23;
            cursor: pointer;
          }

          .title {
            @include h2;

            text-transform: uppercase;
            color: #ffffff;
          }

          .subtitles {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            margin-left: 100px;
            margin-top: 30px;

            .subtitle {
              font-weight: 500;
              font-size: 16px;
              color: #ae6fff;
              border-radius: 100px;
              width: auto;
              height: 44px;
              background: #221631;
              display: flex;
              align-items: center;
              padding: 10px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .wrapper {
    .container {
      .section {
        .title {
          margin-left: 0px;
        }
        .group {
          margin-left: 0px;
        }
      }
    }
  }
}

@media (max-width: 850px) {
  .wrapper {
    .container {
      .section {
        .group {
          .desc {
            padding-left: 0px;
            .subtitles {
              display: flex;
              flex-wrap: wrap;
              gap: 10px;
              margin-left: 10px;
              margin-top: 30px;
            }
          }
          .img {
            width: 70%;
          }
        }
      }
    }
  }
}

@media (max-width: 679px) {
  .wrapper {
    margin-top: 40px;
    .container {
      margin: 0px;
      .section {
        width: 100%;
        .title {
          font-weight: 400;
          font-size: 40px;
          line-height: 100%;
          letter-spacing: 0.03em;
          text-transform: uppercase;
          color: #f8ff13;
        }
        .group {
          height: auto;
          padding: 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 15px;
          background: linear-gradient(180deg, #4f1894 0%, #1b1e23 100%);

          .img {
            display: none;
          }
          .img_mob {
            display: block;
            object-fit: cover;
            height: 100%;
            width: auto;
          }
          .desc {
            padding-left: 0;
            display: flex;
            flex-direction: column;
            gap: 0px;
            align-items: center;
            .button {
              @include subtitle4m;
              
              border-radius: 8px;
              width: 118px;
              height: 50px;
              background: #ead40c;
              margin-top: 30px;
              line-height: 130%;
              text-transform: capitalize;
              color: #1b1e23;
              cursor: pointer;
            }

            .title {
              font-size: 36px;
              margin-left: 0px;
              text-align: center;
            }
            .subtitles {
              display: flex;
              flex-direction: row;
              align-items: center;
              margin-left: 0px;
              justify-content: center;
              .subtitle {
                font-size: 14px;
                height: auto;
                padding: 8px;
                margin-top: 0px;
                gap: 0px;
              }
            }
          }
        }
      }
    }
  }
}
