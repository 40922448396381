@import '../../assets/styles/global.scss';

.container {
  margin-top: 24px;

  .title {
    @include subtitle1b;

    align-items: center;
    color: $c-white;
    display: flex;
    gap: 8px;
  }

  .table {
    margin-top: 24px;

    .head {
      @include subtitle4m;

      background-color: #11362C;
      border-radius: 9px 9px 0 0;
      color: #07EF9C;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      height: 60px;

      .headItem {
        align-items: center;
        display: flex;
        padding-left: 18px;
      }

      .headerCenter {
        border-left: 1px solid #07ef9c1a;
        border-right: 1px solid #07ef9c1a;
      }
    }

    .body {
      background-color: rgba(0, 0, 0, .3);
      border-radius: 0 0 9px 9px;

      .bodyItem {
        @include body1;

        color: $c-white;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        padding-bottom: 7px;
        padding-left: 16px;
        padding-top: 7px;

        div {
          align-items: center;
          display: flex;
        }

        .status {

          div {
            background-color: #11362C;
            border-radius: 100px;
            color: #07EF9C;
            display: inline-flex;
            height: 36px;
            padding: 8px 12px;
          }

          .failed {
            background-color: $c-dark-red;
            color: $c-red;
            position: relative;
          }

          .send {
            background-color: $c-dark-blue;
            color: $c-blue;
          }

          .error {
            background-color: unset;
            height: unset;
            padding: 0;
            left: calc(100% + 8px);
            position: absolute;
            width: unset;

            img {
              cursor: pointer;
            }

            .info {
              background-color: #47451E;
              border-radius: 8px;
              bottom: calc(100% + 21px);
              display: block;
              height: unset;
              left: 50%;
              padding: 12px;
              transform: translateX(-50%);
              width: 334px;
              position: absolute;

              .link {
                color: #EAD40C;
              }

              .infoTitle {
                @include subtitle6m;

                color: #EAD40C;
                background-color: unset;
                display: block;
                height: unset;
                padding: 0;
                position: relative;
              }

              .infoTxt {
                background-color: unset;
                border-bottom: 1px solid rgba(220, 220, 220, .1);
                border-radius: 0;
                color: #E0E0E0;
                display: block;
                font-size: 12px;
                font-weight: 400;
                height: unset;
                margin-top: 8px;
                padding: 0;
                padding-bottom: 8px;
                position: relative;
              }

              .accept {
                @include buttonsmall;


                background-color: #EAD40C;
                border-radius: 8px;
                color: #1B1E23;
                cursor: pointer;
                margin-top: 8px;
                padding: 6px 10px;
              }

              .carret {
                background-color: unset;
                border: 0;
                padding: 0;
                left: 50%;
                position: absolute;
                transform: translateX(-50%);
                top: 88%;
              }
            }
          }
        }
      }

      .pagination {
        display: flex;
        justify-content: end;
      }
    }
  }
}

@media (max-width: 850px) {
  .container {
    .table {

      .head {

        .headItem {
          @include subtitle7m;

          justify-content: center;
          padding-left: unset;
          text-align: center;
        }
      }

      .body {

        .bodyItem {
          @include tableinput;

          padding: 16px 8px;

          .bodyItemName {
            padding-left: 8px;
          }

          .status {
            @include buttonmedium;
            
            display: flex;
            justify-content: center;

            .failed {
              padding: 6px 8px;
            }

            .error {
              left: calc(100% + 4px);

              .info {
                left: unset;
                right: 0;
                transform: unset;

                .carret {
                  left: 90%;
                  transform: unset;
                }
              }
            }
          }
        }
      }
    }
  }
}