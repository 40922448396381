@import '../../assets/styles/global.scss';

.wrapper {
  position: relative;
  @include subtitle6m;
  @include df-aic-g8;
  color: $c-yellow;

  cursor: pointer;
  margin-bottom: 24px;
}