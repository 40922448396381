@import '../../../assets/styles/global.scss';

.wrapper {
  // main-season down
  // background-image: url('../../../assets/images/tournaments.jpg');
  
  //Winter-season down
  background-image: url('../../../assets/images/tournaments-winter.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 110px;
  padding-bottom: 80px;

  .container {
    margin: 0 auto;
    width: 80%;
    padding-top: 30px;

    .header {
      align-items: center;
      border-bottom: 1px solid #E0E0E059;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      padding-bottom: 31px;
      margin-bottom: 24px;

      .month {
        @include subtitle1b;

        align-items: center;
        color: #fff;
        display: flex;
        gap: 20px;
        justify-content: center;

        .arrowWrapper {
          align-items: center;
          background-color: #FAF9F833;
          border-radius: 8px;
          cursor: pointer;
          display: flex;
          height: 32px;
          justify-content: center;
          width: 32px;
        }

        .monthTxt {
          align-items: center;
          display: flex;
          padding-top: 5px;
        }
      }

      .filter {
        align-items: center;
        display: flex;
        gap: 12px;
        justify-content: flex-end;

        .filterWrapper {
          @include body1;

          align-items: center;
          background-color: #DCDCDC0D;
          border-radius: 100px;
          color: #E0E0E0;
          cursor: pointer;
          display: flex;
          justify-content: center;
          padding: 10px 16px;
        }

        .activeFilter {
          background-color: #333217;
          color: #ead40c;
        }
      }
    }

    .grid {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
    }

    .dayOfWeek {
      text-align: center;
      margin-bottom: 24px;
    }

    .dayOfWeekWrapper {
      background-color: #FFFFFF;
      border-radius: 100px;
      color: #1B1E23;
      display: inline;
      font-size: 14px;
      font-weight: 500;
      padding: 6px 12px;
    }

    .day {
      min-height: 217px;
    }

    .day,
    .dayEmpty {
      border: 1px solid #DCDCDC1A;
      // padding: 10px;
      padding-top: 31px;
      color: #fff;
      background-color: #000000A6;
      border-radius: 8px;
      // height: 217px;
      padding-bottom: 31px;
      position: relative;

      .dayNum {
        left: 10px;
        position: absolute;
        top: 10px;
      }

      .game {
        bottom: 8px;
        display: flex;
        gap: 10px;
        left: 8px;
        position: absolute;

        .gameItem {
          align-items: center;
          background-color: #611D14;
          border-radius: 100px;
          display: flex;
          gap: 4px;
          font-size: 13px;
          font-weight: 400;
          padding: 6px 7px;
        }

        .gameItemCS {
          background-color: #614714;
        }

        .gameItemLol {
          background-color: #C79B3B26;
        }
      }
    }

    .dayEmpty {
      background-color: transparent;
      border: none;
    }
    .today {
      background-color: #1d1e21; 
      border: 2px solid rgba(220, 220, 220, 0.3);
      // transform: scale(1.05);
      z-index: 0;
    }

    .event {
      cursor: pointer;
      font-size: 13px;
      font-weight: 500;
      margin-bottom: 4px;
      padding: 4px 8px;
      background-color: rgba(0, 123, 255, 0.7);
      font-size: 12px;
      text-overflow: ellipsis;
    }
  }

  .modal {
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    right: 0;
    position: fixed;
    top: 0;
    z-index: 2;

    .modalWrapper {
      background-color: #1B1E23;
      border-radius: 8px;
      width: 50%;

      .modalImg {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        height: 193px;
        width: 100%;
      }

      .modalInfo {
        padding: 16px 20px;

        .modalTitle {
          color: #FFFFFF;
          font-size: 24px;
          font-weight: 700;
        }

        .modalGame {
          align-items: center;
          border-bottom: 1px solid #E0E0E059;

          display: flex;
          gap: 16px;
          margin-top: 16px;
          padding-bottom: 16px;


          .modalGameItem {
            align-items: center;
            background-color: #611D14;
            border-radius: 100px;
            color: #fff;
            display: flex;
            gap: 4px;
            font-size: 13px;
            font-weight: 400;
            padding: 6px 7px;
          }

          .modalGameItemCS {
            background-color: #614714;
          }

          .modalGameItemLol {
            background-color: #C79B3B26;
          }

          .modalCalendar {
            align-items: center;
            color: #FFFFFF;
            display: flex;
            font-size: 16px;
            font-weight: 500;
          }
        }

        .modalDescription {
          color: #E0E0E0;
          font-size: 16px;
          font-weight: 400;
          line-height: 150%;
          margin-top: 16px;
        }

        .modalBtns {
          display: flex;
          gap: 16px;
          justify-content: end;
          margin-top: 20px;
        }
      }
    }
  }
}

@media (max-width: 1291px) {
  .wrapper {

    .container {

      .game {

        .gameItem {

          .gameItemName {
            display: none;
          }
        }
      }
    }
  }
}

@media (max-width: 1100px) {
  .wrapper {

    .container {
      width: 90%;

      .header {
        display: flex;
        justify-content: space-between;

        .emptyDiv {
          display: none;
        }

        .month {
          font-size: 18px;
          gap: 6px;
        }

        .arrowWrapper {
          height: 24px;
          width: 24px;
        }

        .filter {
          justify-content: unset;

          .filterWrapper {
            padding: 8px;
          }
        }
      }
    }
  }
}

@media (max-width: 700px) {
  .wrapper {

    .modal {

      .modalWrapper {
        height: 100%;
        width: 100%;
      }
    }

    .container {

      .day {
        min-height: 157px;
        
        .game {
          gap: unset;
          justify-content: space-between;
          left: 6px;
          right: 6px;

          .gameItem {
            align-items: center;
            background-color: unset;
            display: flex;
            justify-content: center;
            // height: 16px;
            // width: 16px;
            padding: unset;

            img {
              height: 8px;
              width: 8px;
            }
          }
        }
      }



      .event {
        padding: 4px 2px;

        .eventName {
          font-size: 6px;
        }
      }
    }
  }
}